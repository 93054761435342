import { useState, useEffect, useCallback } from 'react';

export enum ListenerResponseTypes {
  SUCCESS = 'success',
  FAILURE = 'failure'
}

type UseAwaitYumAuthListener = () => {
  setListenerResponse: React.Dispatch<React.SetStateAction<ListenerResponseTypes | null>>;
  waitForListenerResponse: (timeout?: number) => Promise<void>;
};
const useAwaitYumAuthListener: UseAwaitYumAuthListener = () => {
  const [listenerResponse, setListenerResponse] = useState<ListenerResponseTypes | null>(null);

  const [resolveFn, setResolveFn] = useState<(() => void) | null>(null);
  const [rejectFn, setRejectFn] = useState<((error: Error) => void) | null>(null);

  useEffect(() => {
    if (listenerResponse === ListenerResponseTypes.SUCCESS && resolveFn) {
      resolveFn();
      setResolveFn(null);
      setRejectFn(null);
      setListenerResponse(null);
    } else if (listenerResponse === ListenerResponseTypes.FAILURE && rejectFn) {
      rejectFn(new Error('Failure encountered'));
      setResolveFn(null);
      setRejectFn(null);
      setListenerResponse(null);
    }
  }, [listenerResponse, resolveFn, rejectFn]);

  const waitForListenerResponse = useCallback((timeout = 5_000) => new Promise<void>((resolve, reject) => {
    setResolveFn(() => resolve);
    if (rejectFn === null) {
      setRejectFn(() => reject);
    }

    setTimeout(() => {
      setListenerResponse(null);
      if (rejectFn) {
        rejectFn(new Error('useYumAuthListener response timed out'));
      } else {
        reject(new Error('useYumAuthListener response timed out'));
      }
      setResolveFn(null);
      setRejectFn(null);
    }, timeout);
  }), [rejectFn]);

  return {
    setListenerResponse,
    waitForListenerResponse
  };
};

export default useAwaitYumAuthListener;
