import React, { ReactElement } from 'react';
import {
  AppBar, Button, Grid
} from '@material-ui/core';
import { useStyles } from './RailHeader.styles';
import { ENTER_KEY_EVENT_CODE, KEYBOARD_ENTER_KEYCODE } from '@/common/constants';
import CloseIcon from '@/localization/icons/CloseIcon';

interface RailHeaderProps {
  title: string;
  onClose: () => void;
  icon?: ReactElement;
}

export default function RailHeader({
  title,
  onClose,
  icon
}: RailHeaderProps): JSX.Element {
  const classes = useStyles();

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY_EVENT_CODE || event.keyCode === KEYBOARD_ENTER_KEYCODE) {
      event.preventDefault();
      onClose();
    }
  };

  return (
    <AppBar color="inherit" position="sticky" classes={{ root: classes.root }}>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.title}>
        <Grid item>
          <span data-testid="rail-header-icon" className={classes.icon}>
            {icon}
          </span>
          <span data-testid="rail-header-label" className={classes.label}>
            {title}
          </span>
        </Grid>
        <Grid item>
          <Button
            data-testid="rail_close"
            aria-label="Close rail"
            onClick={onClose}
            onKeyDown={onKeyDown}
            classes={{ root: classes.closeIcon }}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
}
