export const CART_EMPTY_WARNING_MODAL = {
  TITLE: 'Cart will be emptied',
  BODY: "If you choose to sign in, your cart will be emptied.  If you're a Hut Rewards member, points will still be earned without signing in.",
  CTAS: {
    PRIMARY: 'Continue As Guest',
    SECONDARY: 'Sign in and start over'
  }
};

export const SIGN_IN_WARNING_MODAL = {
  TITLE: 'Sign in required',
  BODY: 'You must sign in to redeem this deal',
  CTAS: {
    PRIMARY: 'Sign In',
    SECONDARY: 'Cancel'
  }
};
