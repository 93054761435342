import Rail from './Rail';
import { RailBodyContainer } from './layout/RailBodyContainer';
import { RailContentsContainer } from './layout/RailContentsContainer';
import { RailCtasContainer } from './layout/RailCtasContainer';

export default Rail;

export {
  RailContentsContainer,
  RailBodyContainer,
  RailCtasContainer
};
