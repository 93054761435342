import React from 'react';
import {
  Button, Grid, makeStyles, Theme
} from '@material-ui/core';
import colors from '@/common/colors';
import { mobileStartBreakpoint } from '@/materialUi/theme';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `${colors.red} 1px solid`,
    fontFamily: 'open_sans_semi',
    fontSize: 12,
    fontWeight: 600,
    width: '156px',
    margin: '8px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      margin: '5px 14px 5px 0'
    }
  },
  text: {
    paddingLeft: '16px'
  }
}));

interface IconTextButtonProps {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}

export default function IconTextButton({ icon, text, onClick }: IconTextButtonProps): JSX.Element {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={onClick}>
      {icon}
      <Grid className={classes.text}>{text}</Grid>
    </Button>
  );
}
