import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

interface ProfileArrowIconProps {
  classes: { [key: string]: string; };
  open: boolean;
  customerDetails: CustomerDetails;
}

const ProfileArrowIcon = ({ customerDetails, classes, open }: ProfileArrowIconProps) => {
  if (!customerDetails) {
    return <ArrowForwardIosIcon fontSize="small" className={classes.caratIcon} />;
  }

  return open
    ? <ExpandLessIcon fontSize="medium" className={classes.caratIcon} />
    : <ExpandMoreIcon fontSize="medium" className={classes.caratIcon} />;
};

export default ProfileArrowIcon;
