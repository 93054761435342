import { Backdrop, Drawer, List } from '@material-ui/core';
import React from 'react';
import { Anchor } from '../headerTypes';

interface MobileDrawerProps {
  open: boolean;
  anchor: Anchor;
  testId: string;
  items: JSX.Element[];
  classes: { [key: string]: string };
}

const MobileDrawer = ({
  open, anchor = 'left', testId, items, classes
}: MobileDrawerProps) => (
  <>
    <Backdrop open={open} className={classes.menuBackdrop} transitionDuration={300} />
    <Drawer
      open={open}
      anchor={anchor}
      variant="persistent"
      transitionDuration={300}
      classes={{ paper: classes.menuDropdownMobileWrapper }}
      data-testid={`mobile-${testId}-drawer`}
    >
      <div className={classes.menuDropdownMobile} role="presentation">
        <List disablePadding>
          {items}
        </List>
      </div>
    </Drawer>
  </>
);

export default MobileDrawer;
