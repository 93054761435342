import getConfig from 'next/config';
import { useCallback, useEffect, useRef } from 'react';
import {
  PostMessageHandler,
  YumAuthMessage,
  YumMessagePredicateWithCallback
} from '@/rail/railContent/signInRail/signInIframe/hooks/UseYumAuthListener.types';
import useAwaitYumAuthListener, { ListenerResponseTypes } from './useAwaitYumAuthListener';

// Predicate builders
export const messageTypeMatches = (messageType: string) => (data: unknown): data is YumAuthMessage => (data as YumAuthMessage).type === messageType;
export const messageTypeDoesntMatch = (messageTypes: string[]) => (data: unknown): data is YumAuthMessage => !messageTypes.includes((data as YumAuthMessage).type);

const eventNotFromIframe = (sourceUrl: string) => (
  event: MessageEvent<unknown>
): boolean => {
  const url = new URL(sourceUrl);
  return !event.origin.includes(url.hostname) && !event.origin.includes('.pizzahut.com');
};

const preventMessagesFromUnknown = (handler: PostMessageHandler, sourceUrl: string) => {
  const isEventNotFromIframe = eventNotFromIframe(sourceUrl);
  return (event: MessageEvent<unknown>) => {
    if (isEventNotFromIframe(event)) {
      return;
    }
    handler(event);
  };
};

export const authListener = (
  listeners: YumMessagePredicateWithCallback[],
  sourceUrl: string,
  setListenerResponse: React.Dispatch<React.SetStateAction<ListenerResponseTypes | null>>
): PostMessageHandler => preventMessagesFromUnknown((event) => {
  listeners.forEach((listener) => {
    if (listener.predicate(event.data)) {
      if ('responseType' in listener && listener.responseType) {
        setListenerResponse(listener.responseType);
      }
      if ('callback' in listener && typeof listener.callback === 'function') {
        listener.callback();
      }
    }
  });
  return undefined;
}, sourceUrl);

type UseYumAuthListenerProps = (
  listeners: YumMessagePredicateWithCallback[],
  sourceUrl?: string
) => (timeout?: number) => Promise<void>;

const useYumAuthListener: UseYumAuthListenerProps = (listeners, url) => {
  const { publicRuntimeConfig } = getConfig();
  const sourceUrl = url || `${publicRuntimeConfig.YUM_BASE_URL}/oidc/oauth2/auth`;
  const { setListenerResponse, waitForListenerResponse } = useAwaitYumAuthListener();
  const listener = useRef(authListener(listeners, sourceUrl, setListenerResponse));

  const mountListenersCb = useCallback(() => {
    window.addEventListener('message', listener.current);
    return () => {
      window.removeEventListener('message', listener.current);
    };
  }, []);
  useEffect(mountListenersCb, [listeners, sourceUrl, mountListenersCb]);

  return waitForListenerResponse;
};

export default useYumAuthListener;
