import React from 'react';
import useRailLayoutStyles from './styles';

type Props = {
  children: JSX.Element;
} & Record<string, unknown>;

export const RailContentsContainer = ({
  children,
  ...forwardedProps
}: Props): JSX.Element => {
  const classes = useRailLayoutStyles();
  const props = { className: classes.railContent, ...forwardedProps };
  return React.cloneElement(children, props);
};
