import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useVersionQuery } from '@/graphql/hooks/useVersionQuery';
import {
  FOOTER_NATIONAL,
  CC_GQL_PARTIAL_FOOTER_LOCALIZED
} from '@/graphql/queries';
import { onGqlError } from '@/graphql/errors/actions';
import { QueryNames } from '@/graphql/errors/constants';
import { FooterParsedResponse } from '@/graphql/types/Footer';
import useEnvQueryVariables from '@/graphql/hooks/variables/useEnvQueryVariables';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { useCCGetCategoriesQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetCategoriesQuery';
import { CCOccasionChoicesTransformer, CCOccasionPicker } from '@/clientCore/temporaryTransformationalHooks/types';
import { OrEmptyObj } from '@/utils';
import { NATIONAL_STORE_ID_FULL } from '@/localization/constants';

interface ReturnData {
  data: OrEmptyObj<FooterParsedResponse> | undefined;
  loading: boolean;
  error: boolean;
}

const useFooter = (): ReturnData => {
  const dispatch = useDispatch();
  const isLocalized = useSelector(localizationSelectors.isLocalized);
  const envQueryVariables = useEnvQueryVariables();
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const occasion: OccasionChoice = useSelector(localizationSelectors.occasion);

  const formattedOccasion = CCOccasionPicker[occasion as keyof CCOccasionChoicesTransformer];
  const { data: ccData, loading: ccLoading } = useCCGetCategoriesQuery({
    occasion: formattedOccasion,
    storeNumber: storeDetails?.storeNumber ?? NATIONAL_STORE_ID_FULL
  });

  const {
    data,
    loading,
    error: gqlError,
    storeID
  } = useVersionQuery({
    queryFn: isLocalized ? CC_GQL_PARTIAL_FOOTER_LOCALIZED : FOOTER_NATIONAL,
    storeSpecific: true,
    itemId: '',
    options: envQueryVariables,
    nationalOverrides: true,
    productDomain: undefined,
    parserOptions: { customerIsNational: !isLocalized }
  });

  useEffect(() => {
    if (gqlError) {
      dispatch(onGqlError(QueryNames.FOOTER, gqlError, storeID));
    }
  }, [storeID, gqlError, dispatch]);

  const footerPayload = {
    data: { ...data, categories: ccData } as OrEmptyObj<FooterParsedResponse>,
    loading: loading || ccLoading,
    error: Boolean(gqlError)
  };

  return footerPayload;
};

export default useFooter;
