import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { hideLoadingQuery, showLoadingQuery } from '@/localization/actions';

const usePageLoadingBar = (show: boolean): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (show) {
      dispatch(showLoadingQuery());
    } else {
      dispatch(hideLoadingQuery());
    }
  }, [dispatch, show]);
};

export default usePageLoadingBar;
