import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createStyles, Grid, makeStyles, Theme
} from '@material-ui/core';
import Rail from '@/rail';
import { closeConfirmLocationRail, selectors } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import { openLocalizationRail } from '@/localization/actions';
import { RootState } from '@/rootStateTypes';
import { Occasion, OccasionString } from '@/localization/constants';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import MapPinIcon from '@/localization/icons/MapPinIcon';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    padding: '0 24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '0 16px'
    }
  },
  headerLabel: {
    fontSize: '20px',
    fontFamily: 'open_sans_bold',
    margin: '24px 0 45px 5px',
    letterSpacing: '0.4px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '18px'
    }
  },
  elementSpace: {
    paddingBottom: '15px'
  }
}));

const ConfirmLocationRail = (): JSX.Element => {
  const getCurrentlyLocalizedAddress = (state: RootState) => state.presentational.localization.currentlyLocalizedAddress;
  const getLocalizedStoreDetails = (state: RootState) => state.domain.localization.localizedStoreDetails || {};
  const getLocalizedOccasion = (state: RootState) => state.domain.localization.localizedOccasion;
  const visible = useSelector(selectors.isConfirmLocationVisible);
  const currentlyLocalizedAddress = useSelector(getCurrentlyLocalizedAddress);
  const localizedStoreDetails = useSelector(getLocalizedStoreDetails);
  const localizedOccasion = useSelector(getLocalizedOccasion);
  const classes = useStyles();
  const getLocalizedAddress = () => (
    localizedOccasion === Occasion.DELIVERY
      ? currentlyLocalizedAddress?.address
      : (localizedStoreDetails?.address || currentlyLocalizedAddress?.address)
  );
  const occasion = `${OccasionString[localizedOccasion as keyof typeof OccasionString]}`;
  const description = `This order is set for ${occasion} from ${getLocalizedAddress()}`;
  const dispatch = useDispatch();
  const optimizelyInstance = getOrInitializeOptimizely();

  const handleRailClose = () => {
    dispatch(closeConfirmLocationRail(RailType.CONFIRM_LOCATION));
  };

  const continueClicked = () => {
    optimizelyInstance?.track('LandingRailContinue');
    handleRailClose();
  };

  const handleChangeSelection = () => {
    dispatch(openLocalizationRail());
    optimizelyInstance?.track('LandingRailChangeMySelection');
    handleRailClose();
  };

  const railIcon = <MapPinIcon />;

  return (
    <Rail
      title="Welcome back"
      visible={visible}
      onClose={handleRailClose}
      railIcon={railIcon}
    >
      <Grid
        className={classes.content}
        data-test-id="confirm_location_rail"
      >
        <div className={classes.headerLabel}>{description}</div>

        <CtaButton
          labelText="continue"
          color="primary"
          size="large"
          disabled={false}
          clickHandler={continueClicked}
          fullWidth
        />
        <div className={classes.elementSpace} />
        <CtaButton
          labelText="Change my selection"
          color="secondary"
          size="large"
          disabled={false}
          clickHandler={handleChangeSelection}
          fullWidth
        />
      </Grid>
    </Rail>
  );
};

export default ConfirmLocationRail;
