import React, { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { useDecision } from '@optimizely/react-sdk';

import { localizationSelectors } from '@/localization/localizationSelectors';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import {
  switchToCarryout, openLocalizationRail, openModal, closeModal, shouldPrefillInput
} from '@/localization/actions';
import modalHelper from '@/account/orders/modalHelper';

import CaliforniaDeliveryFeeAdvisory from '@/common/CaliforniaDeliveryFeeAdvisory';
import DeliveryInfo from '@/common/DeliveryInfo';

import { RootState } from '@/rootStateTypes';

const useCaliforniaDeliveryFeeModal = () => {
  const [displayCaliDeliveryWarningDecision] = useDecision('ops_dtg436_display_cali_delivery_warning');

  const dispatch = useDispatch();

  const localizedStoreAddress = useSelector(localizationSelectors.currentlyLocalizedAddress);
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const localizedOccasion = useSelector(localizationSelectors.occasion);

  // cc cart
  const ccCartQuantity = useSelector(orderSelectors.cart)?.items?.length;
  // legacy cart
  const nonCCCartQuantity = useSelector((state: RootState) => (state.domain.cart?.quantity || state.domain.cartLegacy?.quantity));
  const isCartEmpty:boolean = (nonCCCartQuantity ?? 0) === 0 || (ccCartQuantity ?? 0) === 0;

  const { checkClearCart } = modalHelper;

  const {
    address, city, state, zipcode
  } = localizedStoreAddress;

  const {
    baseDeliveryCharge: deliveryFee,
    state: storeState
  } = storeDetails ?? {};

  useEffect(() => {
    if (!displayCaliDeliveryWarningDecision.enabled) {
      return;
    }

    const switchToCarryoutCallback = () => {
      batch(() => {
        dispatch(openLocalizationRail());
        dispatch(switchToCarryout());
        dispatch(shouldPrefillInput());
      });
    };

    const isCalifornia = storeState?.toLowerCase() === 'ca';
    const isDelivery = localizedOccasion === 'D';

    const showDeliveryFeeAdvisory = ((isCalifornia && isDelivery) && deliveryFee);

    if (showDeliveryFeeAdvisory && window.history.state.idx === 0) {
      // primary button props
      const primaryButtonProps = {
        text: 'CONTINUE WITH DELIVERY',
        reverseButtonsOrder: true,
        callback: () => {
          dispatch(closeModal());
        }
      };

      // secondary button props
      const secondaryButtonProps = {
        text: 'SWITCH TO CARRYOUT',
        reverseButtonsOrder: true,
        callback: () => {
          if (isCartEmpty) {
            switchToCarryoutCallback();
          } else {
            checkClearCart(dispatch, switchToCarryoutCallback);
          }
        }
      };

      // delivery address component
      const DeliveryInfoComponent = (<DeliveryInfo address={address} city={city} state={state} zipcode={zipcode} />);

      // cali delivery fee advisory component
      const CaliforniaDeliveryFeeAdvisoryComponent = (<CaliforniaDeliveryFeeAdvisory fee={deliveryFee} />);

      dispatch(openModal({
        title: 'Confirm location',
        bodyElements: [DeliveryInfoComponent, CaliforniaDeliveryFeeAdvisoryComponent],
        hideCloseIcon: true,
        cta: {
          ...primaryButtonProps
        },
        altCta: {
          ...secondaryButtonProps
        }
      }));
    }
  }, [
    displayCaliDeliveryWarningDecision.enabled,
    localizedOccasion,
    dispatch,
    deliveryFee,
    storeState,
    address,
    city,
    state,
    zipcode,
    isCartEmpty,
    checkClearCart
  ]);
};

export default useCaliforniaDeliveryFeeModal;
