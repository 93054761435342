import React from 'react';
import { Hidden, HiddenProps } from '@material-ui/core';
import { useResponsiveContext } from '../../context/ResponsiveProvider';
import getHiddenImplementation from './getHiddenImplementation';
import { Implementation } from './getHiddenImplementation/getHiddenImplementation';

enum BreakPoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

type Props = HiddenProps & { children: React.ReactNode };

const HiddenOnDevice = (props: Props): JSX.Element => {
  const { initialWidth } = useResponsiveContext() || {};
  const { implementation } = props;
  const getImplementation = getHiddenImplementation(implementation);
  const customProps = getImplementation === 'css'
    ? props
    : { ...props, implementation: 'js' as Implementation, initialWidth: initialWidth as BreakPoints };

  return <Hidden {...customProps} />;
};

export default HiddenOnDevice;
