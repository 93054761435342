import React from 'react';
import { Typography } from '@material-ui/core';

import { useLoyaltyPoints } from '@/clientCore/loyalty';

import { HiddenOnDevice } from '../../common/ResponsiveContext';

interface HutLoyaltyPointsProps {
  classes: { [key: string]: string; };
  customerDetails: CustomerDetails;
}

const HutLoyaltyPoints = ({ customerDetails, classes }: HutLoyaltyPointsProps) => {
  const { currentPoints } = useLoyaltyPoints();
  const points = (currentPoints ?? customerDetails?.availablePoints) === 1 ? 'point' : 'points';
  return (
    <HiddenOnDevice xsDown>
      <Typography
        data-testid="header-loyalty_points-data"
        className={classes.hutPoints}
      >{`${currentPoints?.toLocaleString() ?? customerDetails?.availablePoints?.toLocaleString()} ${points}`}
      </Typography>
    </HiddenOnDevice>
  );
};

export default HutLoyaltyPoints;
