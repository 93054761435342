import { Box, Grid } from '@material-ui/core';
import React from 'react';
import useRailLayoutStyles from './styles';

type Props = {
  children: JSX.Element;
} & Record<string, unknown>;

export const RailBodyContainer = ({ children, ...props }: Props): JSX.Element => {
  const classes = useRailLayoutStyles();

  return (
    <Box className={classes.bodyContainer} {...props}>
      <Grid container spacing={2} className={classes.bodyContainerGrid}>
        {children}
      </Grid>
    </Box>
  );
};
