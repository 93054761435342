import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mobileStartBreakpoint } from '@/materialUi/theme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    padding: '12px',
    fontFamily: 'PizzaHutFont',
    fontSize: '48px',
    marginBottom: '0',
    width: 'auto',
    height: '65px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '30px',
      height: '48px',
      justifyContent: 'center'
    }
  },
  closeIcon: {
    minWidth: '40px'
  },
  label: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'relative'
    }
  },
  title: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      alignItems: 'center'
    }
  },
  icon: {
    paddingRight: '15px'
  }
}));
