import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import CartButtonSkeleton from './CartNavBarButton.skeleton';
import { useCartNavBarButton } from './hooks/useCartNavBarButton';
import { cartNavBarButtonStyles } from './CartNavBarButton.styles';
import { SCREEN_READER_CART_TEXT } from './constants';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import { CartButtonProps } from './types';

const CartNavBarButton = ({ cartLinkTitle, cartImage }: CartButtonProps): JSX.Element => {
  const classes = cartNavBarButtonStyles();
  const [data, handlers] = useCartNavBarButton();
  const { displayableTotal, isCartLoading, totalItems } = data;
  const { handleToggleRail, onKeyDown } = handlers;

  return isCartLoading
    ? <CartButtonSkeleton />
    : (
      <Grid item className={classes.cartGrid}>
        <div
          role="button"
          className={classes.cartLink}
          onClick={handleToggleRail}
          onKeyDown={onKeyDown}
          tabIndex={0}
          data-testid="header-cart"
          aria-haspopup
        >
          <Grid className={classes.cartImage}>
            <span
              data-testid="header-cart-quantity"
              aria-label={`${totalItems} items in cart.`}
              className={clsx(classes.cartQuantity, totalItems && classes.cartQuantityRed)}
            >
              {totalItems}
            </span>
            <ResponsiveImageRender
              testId="header-cart-iconV2"
              {...cartImage}
              title={cartLinkTitle}
              altText=""
              className={classes.cartIcon}
            />
          </Grid>
          <Typography className={classes.cartSubtotal}>
            <span className={clsx(classes['sr-only'], classes['sr-only-focusable'])}>{SCREEN_READER_CART_TEXT}</span>
            {displayableTotal}
          </Typography>
        </div>
      </Grid>
    );
};

export default CartNavBarButton;
