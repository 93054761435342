import { Dispatch } from 'redux';
import { openModal } from '@/localization/actions';

const checkClearCart = (
  dispatch:Dispatch<any>, callback: () => void
): void => {
  dispatch(openModal({
    title: 'Do you want to proceed?',
    body: 'If you change to a new store the items in your cart will be removed.',
    cta: {
      text: 'PROCEED',
      callback: () => {
        if (origin) {
          callback();
        }
      }
    },
    altCta: {
      text: 'CANCEL',
      callback: (): boolean => false
    }
  }));
};

const handleReOrderError = (
  dispatch: Dispatch<any>, message: string | undefined, title?: string
): void => {
  dispatch(openModal({
    title: title || 'We\'re sorry',
    body: message,
    cta: {
      text: 'OK',
      callback: () => {
      }
    }
  }));
};

const modalHelper = {
  checkClearCart,
  handleReOrderError
};

export default modalHelper;
