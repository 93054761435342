import { useGetCategoriesQuery, DiningOccasion } from '@pizza-hut-us-development/client-core';
import { useSelector } from 'react-redux';
import { transformCategoriesQuery } from './transformCategoriesQuery';
import CategoryFragment from '@/graphql/types/fragment/Category';
import { Category } from '@/graphql/types/Category';
import { RootState } from '@/rootStateTypes';
import { NATIONAL_STORE_ID_FULL } from '@/localization/constants';
import { useRefetchOnYumEcommChanged } from '@/clientCore/helper/useRefetchOnYumEcommChanged';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';

declare type MenuCategoriesResponse = {
  data: Category[] | CategoryFragment[] | null;
  loading: boolean;
};

declare type Props = {
  occasion: DiningOccasion;
  storeNumber: string;
  skip?: boolean;
  isMainMenu?: boolean;
};

declare type UseCCGetCategoriesQuery = (arg0: Props) => MenuCategoriesResponse;

export const useCCGetCategoriesQuery: UseCCGetCategoriesQuery = ({
  occasion,
  storeNumber = '',
  skip = false,
  isMainMenu = false
}) => {
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const cmsEnvId = useSelector((state: RootState) => state.domain.cmsEnv.id);

  const isNationalStore = storeNumber === NATIONAL_STORE_ID_FULL;

  const {
    data, isLoading, isFetching, refetch, isUninitialized
  } = useGetCategoriesQuery(
    {
      occasion,
      storeNumber,
      environmentId: cmsEnvId
    },
    {
      skip: !storeNumber || skip,
      refetchOnMountOrArgChange: true // TODO: Remove when authenticated users are supported when isYumEcomm = true
    }
  );
  // TODO: Remove when authenticated users are supported when isYumEcomm = true
  useRefetchOnYumEcommChanged({ refetch, isUninitialized });

  const isQueryLoading = isLoading || isFetching;
  if (skip) {
    return {
      data: null,
      loading: false
    };
  }
  // TODO: Rip this out when we have a better solution from CC
  // Filter out Salad and Sandwich categories from national store data
  const dataToUse = isNationalStore
    ? data?.filter((category) => {
      const categoryName = category.displayName.toLowerCase();
      return !categoryName.includes('salad') && !categoryName.includes('sandwich');
    })
    : data;

  const transformedData = transformCategoriesQuery(dataToUse, isMainMenu);

  if (debugEnabled) {
    transformationDebugLogging('useCCGetCategoriesQuery', useGetCategoriesQuery.name, data, transformedData, debugMode, [
      DebugModeOptions.SITE
    ]);
  }

  return {
    data: transformedData,
    loading: isQueryLoading
  };
};
