import { encode } from 'querystring';
import { useRouter } from 'next/router';

type UseQueryParams = () => {
  addQueryParam: (param: string, value: string) => Promise<boolean>;
  removeQueryParam: (param: string) => Promise<boolean>;
  removeQueryParams: (params: string[]) => Promise<boolean>;
};

const useQueryParams: UseQueryParams = () => {
  const router = useRouter();

  // adds the provided param/value to the query parameters on the current route
  // NOTE - This will cause rerenders of _app.js - beware of unexpected consequences
  const addQueryParam = (param: string, value: string) => {
    const { pathname, query } = router;
    const urlQueryString = encode(query);
    const searchParams = new URLSearchParams(urlQueryString);

    searchParams.set(param, value);

    return router.replace(
      { pathname, query: searchParams.toString() },
      undefined,
      { shallow: true }
    );
  };

  // removes the provided param from the query parameters on the current route
  // NOTE - This will cause rerenders of _app.js - beware of unexpected consequences
  const removeQueryParams = (params: string[]) => {
    const { pathname, query } = router;
    const urlQueryString = encode(query);
    const searchParams = new URLSearchParams(urlQueryString);

    params.forEach((param) => searchParams.delete(param));

    return router.replace(
      { pathname, query: searchParams.toString() },
      undefined,
      { shallow: true }
    );
  };

  // removes the provided query params from the query parameters on the current route
  const removeQueryParam = (param: string) => removeQueryParams([param]);

  return {
    addQueryParam,
    removeQueryParam,
    removeQueryParams
  };
};

export default useQueryParams;
