/* eslint-disable @typescript-eslint/ban-ts-comment, max-classes-per-file */
// @ts-nocheck - Yum Auth requires that protected members are extended, so TS must be disabled

import {
  AuthorizationNotifier,
  AuthorizationRequest,
  RedirectRequestHandler,
  BaseTokenRequestHandler,
  AuthorizationServiceConfiguration,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
  BasicQueryStringUtils,
  FetchRequestor,
  AuthorizationResponse,
  TokenRequestJson,
  TokenResponse
} from '@openid/appauth';
import { v4 as uuid } from 'uuid';
import getConfig from 'next/config';
import telemetry from '@/telemetry';
import isClientSide from '@/common/isClientSide';
import { YumAction } from '@/hooks/useYumChallengeUrl';

export const COMMON_AUTH_REQUEST_KEY = '_appauth_authorization_';

type ExtraAuthorizationParams = {
  action?: YumAction;
  transition_code?: string;
};

if (isClientSide()) {
  const authorizationRequestKey = (handle: string) => `${handle}${COMMON_AUTH_REQUEST_KEY}request`;

  const authorizationServiceConfigurationKey = (handle: string) => `${handle}${COMMON_AUTH_REQUEST_KEY}service_configuration`;

  const AUTHORIZATION_REQUEST_HANDLE_KEY = 'appauth_current_authorization_request';

  RedirectRequestHandler.prototype.performAuthorizationRequest = function (
    configuration,
    request,
    containerId: string
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const handle = this.crypto.generateRandom(10);
    // Stores authorization request data in local storage
    const persisted = Promise.all([
      this.storageBackend.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle),
      request.toJson().then((result) => self.storageBackend.setItem(
        authorizationRequestKey(handle),
        JSON.stringify(result)
      )),
      this.storageBackend.setItem(
        authorizationServiceConfigurationKey(handle),
        JSON.stringify(configuration.toJson())
      )
    ]);

    // Creates iFrame and appends it to yum-sign-in-iframe-container element
    persisted.then(() => {
      const url = self.buildRequestUrl(configuration, request);
      const iframe = document.createElement('iframe');
      iframe.src = url;
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('height', '100%');
      document.getElementById(containerId)?.replaceChildren(iframe);
    });
  };
}

class SimpleQueryStringUtils extends BasicQueryStringUtils {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  parse = function (input) {
    return this.parseQueryString(input.search);
  };
}

export default class YumAuthCallbackApp {
  authorizationHandler?: RedirectRequestHandler;

  code?: string;

  configuration?: AuthorizationServiceConfiguration;

  notifier?: AuthorizationNotifier;

  request?: AuthorizationRequest;

  response?: AuthorizationResponse;

  settings?: Record<string, any>;

  token?: TokenResponse;

  tokenHandler?: BaseTokenRequestHandler;

  constructor() {
    const { publicRuntimeConfig } = getConfig();
    const yumAuthSettings = {
      client_id: publicRuntimeConfig.YUM_CLIENT_ID,
      redirect_uri: publicRuntimeConfig.YUM_AUTH_IFRAME_CALLBACK_URL,
      response_type: 'code',
      authority: `${publicRuntimeConfig.YUM_BASE_URL}/oidc`,
      prepare_transition_uri: `${publicRuntimeConfig.YUM_BASE_URL}/idp/prepare_transition`,
      silent_logout_uri: `${publicRuntimeConfig.YUM_BASE_URL}/idp/oidc/session/end`,
      environment: publicRuntimeConfig.YUM_ENVIRONMENT,
      scope: 'openid offline'
    };
    this.settings = yumAuthSettings;
    this.notifier = new AuthorizationNotifier();
    this.tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    this.authorizationHandler = new RedirectRequestHandler(
      undefined,
      new SimpleQueryStringUtils()
    );

    this.authorizationHandler.setAuthorizationNotifier(this.notifier);

    this.notifier.setAuthorizationListener(async (request, response, error) => {
      if (response) {
        this.request = request;
        this.response = response;
        this.code = response?.code;
      } else {
        telemetry.addNoticeError(error);
      }
    });
  }

  // Fetches service configuration from the authorization server
  async fetchServiceConfiguration(): Promise<void> {
    if (this.configuration) return;
    try {
      const response = await AuthorizationServiceConfiguration.fetchFromIssuer(
        this.settings?.authority,
        new FetchRequestor()
      );
      this.configuration = response;
    } catch (error) {
      telemetry.addNoticeError(error);
    }
  }

  // Performs the authorization request
  async makeAuthorizationRequest(containerId: string, extraParams?: ExtraAuthorizationParams): Promise<void> {
    await this.fetchServiceConfiguration();
    const request = new AuthorizationRequest({
      ...this.settings,
      state: uuid(),
      extras: {
        action: YumAction.SIGNIN,
        ...extraParams
      }
    });

    if (this.configuration) {
      this.authorizationHandler.performAuthorizationRequest(this.configuration, request, containerId);
    } else {
      telemetry.addNoticeError('Fetch Authorization Service configuration, before you call makeAuthorizationRequest().');
    }
  }

  // Makes a token request using the authorization code
  async makeTokenRequest(): Promise<TokenResponse | undefined> {
    await this.fetchServiceConfiguration();

    const request = new TokenRequest({
      client_id: this.settings?.client_id,
      redirect_uri: this.settings?.redirect_uri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: this.code,
      refresh_token: undefined,
      extras: { code_verifier: this.request?.internal.code_verifier },
      state: this.request?.state
    } as TokenRequestJson);

    try {
      this.token = await this.tokenHandler?.performTokenRequest(
        this.configuration,
        request
      );
      this.code = undefined;
      return this.token;
    } catch (error) {
      telemetry.addNoticeError(error);
      return undefined;
    }
  }

  // Checks for the authorization response and handles it if present
  async checkForAuthorizationResponse(): Promise<void> {
    await this.fetchServiceConfiguration();
    return this.authorizationHandler?.completeAuthorizationRequestIfPossible();
  }
}
