import sha256 from 'crypto-js/sha256';
import base64 from 'crypto-js/enc-base64';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { Url } from '@/hooks/YumTypes';

export enum YumAction {
  REGISTER = 'register',
  SIGNIN = 'signin',
  RESET = 'login',
  GUEST_TO_CUSTOMER = 'guestToCustomer'
}

export const CODE_KEY = 'ph_us_web';

const getUrl = ({
  action, transitionCode, url, resetCode
}: Url) => {
  const { publicRuntimeConfig } = getConfig();
  const codeVerifier = base64.parse(CODE_KEY);
  const codeChallenge = sha256(codeVerifier);
  const yumUrl = new URL(url ?? `${publicRuntimeConfig.YUM_BASE_URL}/oidc/oauth2/auth`);

  // TODO: Wire up real redirect URI
  yumUrl.searchParams.set(
    'redirect_uri',
    publicRuntimeConfig.YUM_AUTH_IFRAME_CALLBACK_URL
  );
  yumUrl.searchParams.set('client_id', publicRuntimeConfig.YUM_CLIENT_ID);
  yumUrl.searchParams.set('response_type', 'code');
  yumUrl.searchParams.set('action', action);
  yumUrl.searchParams.set('code_challenge_method', 'S256');
  yumUrl.searchParams.set('code_challenge', codeChallenge.toString());
  if (transitionCode) yumUrl.searchParams.set('transition_code', transitionCode);
  if (resetCode) yumUrl.searchParams.set('reset_code', resetCode);

  return yumUrl;
};

const useYumChallengeUrl = (props: Url): URL => {
  const url = useMemo(() => getUrl(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.action, props.transitionCode, props.url, props.resetCode]);

  return url;
};

export default useYumChallengeUrl;
