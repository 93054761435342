import HiddenOnDevice from './HiddenOnDevice';

export {
  HiddenOnDevice
};

export const Device = {
  DESKTOP: { mdUp: true },
  MOBILE: { smDown: true },
  TABLET: { lgDown: true }
} as const;
