import React from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { Grid } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';

import NavigationFooter, { NavigationFooterSection } from './NavigationFooter';
import MobileAppSocialMediaFooter from './mobileAppAndSocialMedia';
import LegalDisclaimerFooter from './LegalDisclaimerFooter';
import useStyles from './styles';
import {
  getNavigationFooterList,
  getMobileAppAndSocialMediaList,
  getLegalDisclaimerList
} from './gqlDataTransformer';
import { handleLogout } from '@/common/logout/actions';
import { toggleRail } from '@/rail/slices/Rail.slice';
import { RootState } from '@/rootStateTypes';
import { RailType } from '@/rail/slices/Rail.slice.types';
import useFooter from '@/footer/hooks/useFooter';
import { localizationSelectors } from "@/localization/localizationSelectors";
import { setIsAuthenticated } from '@/../optimizely/utils/attributeHelpers';
import { openModal } from '@/localization/actions';
import { CART_EMPTY_WARNING_MODAL } from '@/header/profile/constants';
import { AsyncDispatch } from '@/localization/localizeActions';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { actions as profileActions } from '@/account/profile/profile.slice';
import { orderSelectors as CCOrderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import authService from '@/services/authService';
import telemetry from '@/telemetry';
import { onSignInSignOutClick } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

interface FooterProps {
  signedIn: boolean;
  criticalGqlErrors: boolean;
  handleLogout: (localizationToken: string | undefined) => void;
}

const Footer = ({
  signedIn, criticalGqlErrors, handleLogout
}: FooterProps) => {
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const [{ enabled: yumLogoutFixEnabled }] = useDecision('fr-web-3642-yum_logout_fix');

  const { items } = useSelector(CCOrderSelectors.cart) ?? {
    items: []
    };

    const isLoggedIn = useSelector(userDomainSelectors.isAuthenticated);

  const classes = useStyles();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const localizationToken = useSelector(localizationSelectors.localizationToken);
  const {
    data,
    loading,
    error
  } = useFooter();

  if (loading || !data || criticalGqlErrors || error || !data?.categories?.length) {
    return null;
  }
  
  const openGuestToCustomerWarningModal = (dispatch: AsyncDispatch) => {
    dispatch(
      openModal({
        title: CART_EMPTY_WARNING_MODAL.TITLE,
        body: CART_EMPTY_WARNING_MODAL.BODY,
        cta: {
          text: CART_EMPTY_WARNING_MODAL.CTAS.PRIMARY,
        },
        altCta: {
          text: CART_EMPTY_WARNING_MODAL.CTAS.SECONDARY,
          callback: () => {
            dispatch(toggleRail(RailType.SIGN_IN));
          }
        },
      })
    );
  };

  const handleToggleRail = () => {
    if (isYumEcomm && !isLoggedIn && items.length > 0) {
      openGuestToCustomerWarningModal(dispatch);
    } else {
      dispatch(toggleRail(RailType.SIGN_IN));
    }
  };

  const logoutYum = async () => {
    try {
      await authService.logoutYum();
      handleLogoutHelper();
    } catch (err) {
      const error = err instanceof Error ? err : new Error(typeof err === 'string' ? err : 'Unknown error');
      telemetry.addNoticeError(error);
    }
  }

  const handleLogoutHelper = () => {
    dispatch(handleLogout(localizationToken));
    dispatch(profileActions.logout());
    setIsAuthenticated(false);
    analytics.push(() => onSignInSignOutClick('Sign Out'));
  }

  const navigationSections: NavigationFooterSection[] = getNavigationFooterList(
    data, signedIn, yumLogoutFixEnabled ? logoutYum : handleLogoutHelper, handleToggleRail
  );
  const mobileAppAndSocialMediaSections = getMobileAppAndSocialMediaList(data);
  const legalDisclaimerSections = getLegalDisclaimerList(data);

  return (
    <Grid id="footer" className={classes.footerRoot}>
      <Grid
        container
        className={classes.footerContainer}
        data-testid="footer-container"
      >
        <Grid item xs={12} md={4}>
          <MobileAppSocialMediaFooter {...mobileAppAndSocialMediaSections} />
        </Grid>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={7} className={classes.navigationFooter}>
          <NavigationFooter sections={navigationSections} />
        </Grid>
      </Grid>
      <LegalDisclaimerFooter {...legalDisclaimerSections} />
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    headerError,
    heroError,
    sidekickError
  } = state.presentational.gqlErrors;

  const signedIn = state.presentational.header.customerDetails !== null;
  const criticalGqlErrors = headerError && heroError && sidekickError;

  return {
    signedIn,
    criticalGqlErrors
  };
};

const mapDispatchToProps = { handleLogout };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
