import React, { useRef, useState } from 'react';
import { Button, ClickAwayListener, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Dropdown from '../dropdown';
import categoryListItem from '../dropdown/categoryListItem';
import { getJoinedText } from '../../common/string-formatter';
import telemetry from '../../telemetry';
import useTrackedDecision from '@/dataAnalytics/hooks/useTrackedDecision';

interface MenuProps {
  menuLinkText: string;
  menuCategories: LinkContent[];
  classes: { [key: string]: string };
}

type CategoryKeyList = {
  category_keys: string[];
};
type CategoryLinkContent = {
  [key: string]: LinkContent
};
type CategoryVariation = {
  [key: string]: number
};

const Menu = ({ menuLinkText, menuCategories, classes }: MenuProps) => {
  const [open, setOpen] = useState(false);
  const menuButton = useRef(null);
  const anchorRef = useRef(null);
  const [menuListDecision] = useTrackedDecision('exp-menu-dropdown');

  const handleMenuToggle = () => {
    if (!open) {
      telemetry.addCustomEvent('header-menu-dropdown-open');
    }

    setOpen((open) => !open);
  };

  const handleClose = () => setOpen(false);

  const menuCarat = (iconClass: string) => (
    open
      ? <ExpandLessIcon fontSize="medium" className={iconClass} />
      : <ExpandMoreIcon fontSize="medium" className={iconClass} />
  );

  const controlledMenuCategories = [...menuCategories];
  if(menuListDecision?.enabled && menuListDecision?.variables?.menu_categories) {
    const categoriesKeyList = menuListDecision.variables.menu_categories as CategoryKeyList;
    const categoryLinkContent = menuListDecision.variables.category_link_content as CategoryLinkContent;
    const categoryVariationIndexes = menuListDecision.variables.category_variation as CategoryVariation;
    //Place new category from categoryLinkContent at index from categoryVariationIndexes
    // using keys from categoriesKeyList.
    categoriesKeyList.category_keys.map((categoryKey) => {
      controlledMenuCategories.splice(categoryVariationIndexes[categoryKey], 0, categoryLinkContent[categoryKey]);
    });
  }

  const dropdownItems = controlledMenuCategories.map(
    (category, index) => {
      const testId = `menu-${getJoinedText(category?.linkTitle)}-link`;
      return categoryListItem(category, index, testId, 'header-menu-dropdown-item-click');
    }
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Grid
          item
          ref={anchorRef}
          onClick={handleMenuToggle}
          className={classes.menuItem}
          data-testid="nav-menu"
        >
          <Button
            id="menu"
            className={classes.menuDropdown}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            data-analytics-category="global_header"
            data-analytics-action={menuLinkText}
            disableRipple
            ref={menuButton}
          >
            {menuLinkText}{menuCarat(classes.expandIcon)}
          </Button>
          <Dropdown
            items={dropdownItems}
            gridRef={anchorRef}
            buttonRef={menuButton}
            placement="bottom-start"
            open={open}
            width="230px"
            handleClose={handleClose}
            testId="menu"
          />
        </Grid>
      </ClickAwayListener>
    </>
  );
};

export default Menu;

