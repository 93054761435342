import React, { useState } from 'react';
import { Button } from '@material-ui/core';

const ErrorComponent = () => {
  throw new Error();
};

const ThrowErrorButton = (): JSX.Element => {
  const [throwError, setThrowError] = useState(false);

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setThrowError(true)}
      >
        Throw Error
      </Button>
      {throwError && <ErrorComponent />}
    </>
  );
};

export default ThrowErrorButton;
