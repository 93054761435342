import { useState, useEffect } from 'react';

// Ensures state is only updated client-side

type ReactStateSetter<T> = React.Dispatch<React.SetStateAction<T>>;
type ClientSideCallback<T> = (setValue: ReactStateSetter<T>) => void;

function useClientSideState<T>(initialState: T, fn: ClientSideCallback<T>): [T] {
  const [state, setState] = useState(initialState);
  useEffect(() => fn(setState), [state]);
  return [state];
}

export default useClientSideState;
