/* eslint-disable no-restricted-globals */
import cookie from 'next-cookies';
import { useState } from 'react';
import configOptions, { Config } from '@/configuration/configOptions';

interface CookieContext {
  req?: {
    headers: {
      cookie?: string;
    };
  };
}

type OverrideValues = {
  [cookieName: string]: string;
};

export const readOverride = (ctx: CookieContext, name: string): string => cookie(ctx)[name] ?? '';

export const writeOverride = (name: string, value: string, domain = `.${location.hostname}`): void => {
  document.cookie = `${name}=${value}; Domain=${domain}`;
};
export const clearOverride = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
export const findCookie = (name: string): string => {
  try {
    const allCookies = document.cookie.split('; ');
    const cookieWithName = allCookies.find((cookieStr) => cookieStr.startsWith(name)) || '';
    return cookieWithName.split('=').pop() || '';
  } catch (error) {
    return '';
  }
};

export const readOverrides = (
  ctx: CookieContext,
  options: Config[]
): OverrideValues => {
  const cookieNames = options.map((config) => config.cookieName);
  const cookies = cookieNames.map((name) => [name, readOverride(ctx, name)]);
  return Object.fromEntries(cookies);
};

export const overridesPresent = (): boolean => !document.cookie.includes('vr_test')
  && Object.values(configOptions)
    .flatMap((config) => config)
    .filter((config) => config.overrideAlert)
    .map((config) => config.cookieName)
    .some((name) => document.cookie.includes(`${name}=`));

export const useCookie = (
  cookieName: string,
  initialValue: string
): [string, (newValue: string
  ) => void] => {
  const [currentValue, setValue] = useState(initialValue);
  const onSave = (newValue: string) => {
    if (newValue === '') {
      clearOverride(cookieName);
      setValue(newValue);
      return;
    }

    writeOverride(cookieName, newValue);
    setValue(newValue);
  };
  return [currentValue, onSave];
};
