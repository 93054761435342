import { DiningOccasion } from '@pizza-hut-us-development/client-core';

export interface CCOccasionChoicesTransformer {
  D: DiningOccasion;
  C: DiningOccasion;
}

export const CCOccasionPicker: CCOccasionChoicesTransformer = {
  D: DiningOccasion.DELIVERY,
  C: DiningOccasion.CARRYOUT
};

export type CCCircuitBreakerVariables = {
  useGetProductsByCategoryQuery: boolean;
  useGetPizzaBuilderQuery: boolean;
  useGetDealBuilderQuery: boolean;
  useGetCategoriesQuery: boolean;
  useGetDealsQuery: boolean;
  useGetUpsellQuery: boolean;
  enable_menu_testing: boolean;
  cart: boolean;
};
