import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import borders from '@/common/borders';
import boxShadows from '@/common/boxShadows';
import useStyles from './styles';

interface LocalizationSkeletonProps {
  isMobile?: boolean;
}

interface LocalizationDetailsProps {
  className: string;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  tabIndex?: number;
}

const LocalizationSkeleton = ({ isMobile }: LocalizationSkeletonProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (isMobile) {
      const headerContainer = document.querySelector('header[data-testid="header-container"]');
      (headerContainer as any).style.boxShadow = 'none';
      (headerContainer as any).style.borderBottom = borders.gray400Border;

      window.addEventListener('scroll', () => {
        if (window.scrollY > 36) {
          (headerContainer as any).style.boxShadow = boxShadows.headerShadow;
          (headerContainer as any).style.border = 'none';
        } else {
          (headerContainer as any).style.boxShadow = 'none';
          (headerContainer as any).style.borderBottom = borders.gray400Border;
        }
      });
    }
  }, [isMobile]);

  const LocalizationDetails = ({
    className, ...rest
  }: LocalizationDetailsProps) => (
    <Grid
      className={className}
      data-testid="localization-grid"
      data-analytics-category="global_header"
      {...rest}
    >
      <Grid className={classes.localizationPromiseTime} data-testid="header-promise_time-text">
        <Skeleton animation="pulse" variant="rect" width={110} height={isMobile ? 16 : 14} />
      </Grid>
      <Grid container wrap="nowrap">
        <Grid className={classes.localizationAddress} data-testid="header-address-text">
          <Skeleton animation="pulse" variant="rect" width={isMobile ? 100 : 110} height={isMobile ? 18 : 20} />
        </Grid>
      </Grid>
    </Grid>
  );

  const LocalizationSkeletonDesktop = () => (
    <Grid item container className={classes.localization}>
      <Grid
        className={classes.desktopWrapper}
        tabIndex={0}
      >
        <Skeleton animation="pulse" variant="circle" className={classes.locationIcon} width={35} height={35} />
        <LocalizationDetails className={classes.localizationDetails} />
      </Grid>
    </Grid>
  );

  const LocalizationSkeletonMobile = () => (
    <Grid className={classes.mobileWrapper}>
      <LocalizationDetails className={classes.localizationDetailsMobile} />
    </Grid>
  );

  return isMobile
    ? <LocalizationSkeletonMobile />
    : <LocalizationSkeletonDesktop />;
};

export default LocalizationSkeleton;
