import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DeliveryIcon } from '@/checkout/icons/DeliveryIcon';
import fontStyles from '@/common/fontStyles';
import { openLocalizationRail, switchToCarryout, switchToDelivery } from '@/localization/actions';
import CarryoutIcon from '@/localization/icons/CarryoutIcon';
import IconTextButton from './IconTextButton';
import colors from '@/common/colors';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '74px'
  },
  fixedWrapper: {
    border: `${colors.gray601} solid`,
    borderWidth: '1px 0',
    boxSizing: 'border-box'
  },
  container: {
    ...fontStyles.centeredContainerWrapper,
    ...fontStyles.centeredContainer,
    margin: 'auto',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  text: {
    paddingRight: '18px'
  },
  fixedPosition: {
    position: 'fixed',
    top: '0',
    right: '0',
    left: '0',
    backgroundColor: `${colors.white}`,
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      position: 'relative'
    }
  }
}));

export default function LocalizationBar(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const localizationBarContainerRef = useRef<HTMLDivElement | null>(null);
  const initialLocalizationBarPositionRef = useRef<number>(0);

  useEffect(() => {
    initialLocalizationBarPositionRef.current = (localizationBarContainerRef.current as HTMLDivElement).getBoundingClientRect().top;

    const handleFixLocalizationBarToTop = () => {
      if (localizationBarContainerRef.current) {
        const currentDistanceFromTop = localizationBarContainerRef.current.getBoundingClientRect().top;
        const initialDistanceFromTop = initialLocalizationBarPositionRef.current;

        if (currentDistanceFromTop <= 0 && window.scrollY >= initialDistanceFromTop) {
          localizationBarContainerRef.current.classList.add(classes.fixedPosition);
        } else {
          localizationBarContainerRef.current.classList.remove(classes.fixedPosition);
        }
      }
    };

    window.addEventListener('scroll', handleFixLocalizationBarToTop);

    return () => {
      window.removeEventListener('scroll', handleFixLocalizationBarToTop);
    };
  }, [classes.fixedPosition]);

  const onCarryoutClick = () => {
    dispatch(openLocalizationRail());
    dispatch(switchToCarryout());
  };

  const onDeliveryClick = () => {
    dispatch(openLocalizationRail());
    dispatch(switchToDelivery());
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid container className={classes.fixedWrapper} ref={localizationBarContainerRef}>
        <Grid container className={classes.container}>
          <Typography className={classes.text}>Select your store for local menus & pricing</Typography>
          <Grid>
            <IconTextButton
              icon={<CarryoutIcon />}
              text="Carryout"
              onClick={onCarryoutClick}
            />
            <IconTextButton
              icon={<DeliveryIcon />}
              text="Delivery"
              onClick={onDeliveryClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
