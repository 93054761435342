import React from 'react';

const SvgComponent = (props: any) => (
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21" height="28" viewBox="0 0 21 28" {...props}>
    <defs>
      <path id="ustlz6629a" d="M9.71.553c5.056 0 9.158 4.114 9.158 9.187 0 1.533-.443 3.284-1.31 5.258-.718 1.635-1.714 3.393-2.967 5.255-.881 1.31-1.853 2.62-2.88 3.889-.33.408-.65.792-.95 1.142-.156.18-.287.33-.369.421l-.682.758-.683-.758c-.082-.091-.213-.241-.368-.421-.3-.35-.62-.734-.95-1.142-1.027-1.27-2-2.578-2.88-3.889-1.253-1.862-2.25-3.62-2.968-5.255C.994 13.024.55 11.273.55 9.74.552 4.667 4.654.553 9.71.553zm.573 22.426c.993-1.227 1.933-2.493 2.784-3.757 1.191-1.772 2.137-3.439 2.808-4.968.764-1.739 1.154-3.257 1.154-4.514 0-4.054-3.278-7.342-7.32-7.342-4.04 0-7.318 3.288-7.318 7.342 0 1.257.39 2.775 1.153 4.514.671 1.529 1.617 3.196 2.809 4.968.85 1.264 1.79 2.53 2.783 3.757.196.242.388.475.574.697.186-.222.377-.455.573-.697zm-.72-10.62c1.522 0 2.758-1.24 2.758-2.767s-1.236-2.767-2.758-2.767c-1.523 0-2.759 1.24-2.759 2.767s1.236 2.767 2.758 2.767zm0 1.845c-2.539 0-4.598-2.066-4.598-4.612 0-2.546 2.06-4.612 4.598-4.612s4.597 2.066 4.597 4.612c0 2.546-2.06 4.612-4.598 4.612z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.438 .719)">
      <mask id="1dci5woylb" fill="#fff">
        <use xlinkHref="#ustlz6629a" />
      </mask>
      <use fill="#E52A33" fillRule="nonzero" xlinkHref="#ustlz6629a" />
      <g mask="url(#1dci5woylb)">
        <path fill="#131313" d="M0 0H35.308V35.417H0z" transform="translate(-7.356 -4.427)" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
