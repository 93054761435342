import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { mobileStartBreakpoint } from '../materialUi/theme';
import borders from '../common/borders';
import fontStyles from '../common/fontStyles';
import colors from '../common/colors';

export default makeStyles((theme: Theme) => createStyles({
  footerRoot: {
    margin: '16px',
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      margin: 0,
      padding: 0
    }
  },
  footerContainer: {
    border: '0',
    borderTop: borders.gray400Border,
    marginTop: '48px',
    paddingTop: '13px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      borderBottom: borders.gray400Border,
      paddingTop: 0,
      marginTop: '32px'
    }
  },
  navigationFooter: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      borderTop: borders.gray400Border
    }
  },
  footerSectionGrid: {
    paddingRight: '23px'
  },
  navigationList: {
    margin: 0,
    padding: 0
  },
  footerLinkParagraph: {
    margin: 0,
    marginBottom: '5px',
    lineHeight: '16px',
    listStyle: 'none'
  },
  subLink: {
    ...fontStyles.footerTextLink,
    fontFamily: 'open_sans',
    marginBottom: '5px',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  expactedRoot: {
    display: 'flex',
    marginBottom: '16px'
  },
  expandedSectionHeader: {
    ...fontStyles.h5,
    lineHeight: '18px',
    marginTop: '18px',
    marginBottom: '5px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.headerPersonalizationLinkMobile
    }
  },
  compactSectionHeader: {
    ...fontStyles.headerPersonalizationLink,
    marginBottom: '5px',
    textTransform: 'uppercase',
    marginTop: 0,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.headerPersonalizationLinkMobile
    }
  },
  root: {
    display: 'flex'
  },
  headerText: {
    ...fontStyles.h5,
    margin: 0,
    height: '48px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: '36px',
      textTransform: 'uppercase'
    }
  },
  linkText: {
    ...fontStyles.headerSubNavLinks,
    padding: '13px 14px 15px 16px',
    lineHeight: 1.71,
    textDecoration: 'none',
    borderBottom: `1px solid ${colors.gray400}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
    fontSize: '12px',
    cursor: 'pointer'
  },
  headerIcon: {
    ...fontStyles.caretIcon,
    margin: 'unset',
    marginLeft: '5px',
    marginTop: '5px'
  },
  expansionSummary: {
    minHeight: '46px',
    height: '46px',
    padding: '0 19px',
    '&.Mui-expanded': {
      minHeight: '48px',
      borderLeft: '3px solid #e71316',
      borderTop: `1px solid ${colors.gray400}`
    }
  },
  expansionSummaryContent: {
    margin: 0,
    height: '100%',
    padding: '15px 0',
    '&.Mui-expanded': {
      margin: 0,
      height: 'inherit'
    }
  },
  expansionPanel: {
    '&.MuiExpansionPanel-root.Mui-expanded': {
      margin: 0
    }
  },
  expansionDetails: {
    display: 'grid',
    boxShadow: 'inset 0 0 8px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#f1f1f1',
    minWidth: '100%',
    padding: '0'
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
      boxShadow: 'none'
    }
  },
  accordionLink: {
    width: '100%'
  }
}));
