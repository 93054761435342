import React from 'react';

const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="38" viewBox="0 0 26 38" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(-5.2 -1)">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M18.285 36.737c.413-.492.873-1.056 1.37-1.683 1.416-1.793 2.833-3.732 4.155-5.743 1.742-2.65 3.183-5.226 4.221-7.645 1.156-2.693 1.773-5.107 1.773-7.166 0-6.594-5.209-11.942-11.63-11.942-6.422 0-11.63 5.348-11.63 11.942 0 2.059.617 4.473 1.773 7.166 1.038 2.419 2.479 4.994 4.22 7.645 1.322 2.011 2.74 3.95 4.157 5.743.496.627.956 1.191 1.369 1.683l.11.132.112-.132z"
      />
      <ellipse cx="17.957" cy="14.277" fill="#E52A33" rx="5.435" ry="5.58" />
      <path
        fill="#131313"
        fillRule="nonzero"
        d="M18.174 1.442c7.022 0 12.717 5.848 12.717 13.058 0 2.236-.654 4.793-1.865 7.616-1.067 2.487-2.54 5.117-4.315 7.819-1.34 2.04-2.777 4.005-4.213 5.822-.503.636-.97 1.209-1.39 1.709-.252.3-.433.511-.53.622l-.404.459-.403-.459c-.098-.11-.28-.322-.532-.622-.42-.5-.886-1.073-1.39-1.709-1.435-1.817-2.871-3.781-4.213-5.822-1.775-2.702-3.247-5.332-4.314-7.82-1.211-2.822-1.865-5.38-1.865-7.615 0-7.21 5.695-13.058 12.717-13.058zm.111 35.295c.413-.492.873-1.056 1.37-1.683 1.416-1.793 2.833-3.732 4.155-5.743 1.742-2.65 3.183-5.226 4.221-7.645 1.156-2.693 1.773-5.107 1.773-7.166 0-6.594-5.209-11.942-11.63-11.942-6.422 0-11.63 5.348-11.63 11.942 0 2.059.617 4.473 1.773 7.166 1.038 2.419 2.479 4.994 4.22 7.645 1.322 2.011 2.74 3.95 4.157 5.743.496.627.956 1.191 1.369 1.683l.11.132.112-.132zM17.957 19.3c2.7 0 4.89-2.25 4.89-5.022 0-2.772-2.19-5.023-4.89-5.023-2.7 0-4.892 2.25-4.892 5.023 0 2.772 2.192 5.022 4.892 5.022zm0 1.116c-3.3 0-5.979-2.75-5.979-6.138 0-3.389 2.678-6.139 5.979-6.139 3.3 0 5.978 2.75 5.978 6.139 0 3.388-2.678 6.138-5.978 6.138z"
      />
    </g>
  </svg>
);

export default SvgComponent;
