import React from 'react';
import {
  AppBar, Grid, Theme, createStyles, makeStyles
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ProfileSkeleton from './profile/ProfileSkeleton';
import CartButtonSkeleton from '@/clientCore/cart/components/CartRail/components/CartNavBarButton/CartNavBarButton.skeleton';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import fontStyles from '@/common/fontStyles';
import { zIndex } from '@/localization/constants';
import colors from '@/common/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: colors.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    position: 'relative',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'sticky'
    },
    zIndex: zIndex.LOWER_THAN_GOOGLE_AUTO_COMPLETE - 1
  },
  containerGrid: {
    ...fontStyles.centeredContainerWrapper,
    ...fontStyles.centeredContainer,
    height: '70px',
    margin: 'auto',
    alignItems: 'center',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.centeredContainerMobile,
      height: '50px'
    },
    overflow: 'hidden'
  },
  menuItems: {
    flex: 13,
    display: 'flex',
    height: 'inherit',
    alignItems: 'center'
  },
  menuItem: {
    marginLeft: '24px',
    display: 'flex',
    height: 'inherit',
    alignItems: 'center',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginLeft: '12px',
      '&:first-child': {
        marginLeft: '8px'
      }
    }
  },
  mockText: {
    width: '50px',
    height: '35px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '32px',
      height: '20px'
    }
  },
  phLogo: {
    width: '59px',
    height: '47px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '45px',
      height: '34px'
    },
    borderRadius: '5px'
  }
}));

export const HeaderSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <AppBar
        color="inherit"
        classes={{ root: classes.root }}
        data-testid="header-container"
        role="banner"
        elevation={1}
      >
        <Grid container className={classes.containerGrid}>
          <Skeleton animation="pulse" variant="rect" className={classes.phLogo} />
          <Grid item className={classes.menuItems} role="navigation">
            <Grid className={classes.menuItem}>
              <Skeleton animation="pulse" variant="text" className={classes.mockText} />
            </Grid>
            <Grid className={classes.menuItem}>
              <Skeleton animation="pulse" variant="text" className={classes.mockText} />
            </Grid>
          </Grid>
          <ProfileSkeleton />
          <CartButtonSkeleton />
        </Grid>
      </AppBar>
    </>
  );
};
