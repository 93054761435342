import { useDecision, OptimizelyDecision } from '@optimizely/react-sdk';
import { useEffect, useRef } from 'react';
import { OptimizelyAnalytics } from '../analyticsTypes';
import useAnalytics from './useAnalytics';
import { NOT_BUCKETED } from './constants';

declare type UseTrackedDecisionOptions = {
  optimizelyOptions?: {
    autoUpdate?: boolean;
    timeout?: number;
  };
  skipAnalytics?: boolean;
  // decideOptions?: OptimizelyDecideOption[] - available in the future if needed, would need to grab types from optimizely
};

declare type UseTrackedDecisionOverrides = {
  overrideUserId?: string;
  overrideAttributes?: { [name: string]: any };
};

declare type DecisionAnalyticsType = {
  variables?: {
    flag_details?: {
      [key: string]: {
        variationId: number;
        campaign: number;
      };
    };
  };
  flagKey: string;
  variationKey: string;
  ruleKey: string;
};

export const onOptimizelyDecisionWithVariationId = (decision: DecisionAnalyticsType): OptimizelyAnalytics => {
  const flagKeyDetails = decision.variables?.flag_details?.[decision.variationKey];
  const campaignId = flagKeyDetails?.campaign ?? '';
  return {
    event: `optimizely_${decision.flagKey}_${campaignId}`,
    optimizely_flag_name: decision.flagKey,
    optimizely_variation_name: decision.variationKey ?? '',
    beacon_id: '101.01.02',
    optimizely_variation_id: flagKeyDetails?.variationId,
    optimizely_experiment_name: decision.ruleKey
  };
};

export const onOptimizelyDecisionMade = (decision: DecisionAnalyticsType): OptimizelyAnalytics => ({
  event: 'optimizely_test_started',
  optimizely_flag_name: decision.flagKey,
  optimizely_variation_name: decision.variationKey ?? '',
  optimizely_experiment_name: decision.ruleKey,
  beacon_id: '101.01.02'
});

const useTrackedDecision = (flagkey: string, options?: UseTrackedDecisionOptions, overrides?: UseTrackedDecisionOverrides): [OptimizelyDecision] => {
  const optimizelyResponse = useDecision(flagkey, options?.optimizelyOptions, overrides);
  const [decision] = optimizelyResponse;
  const analytics = useAnalytics();
  const disableForNotBucketed = useRef(decision.enabled);
  const wasAnalyticsPushed = useRef(false);
  const [analyticsPushDecision] = useDecision('fr-web-1707-enable_optimizely_test_started_event_single_fire');
  useEffect(() => {
    if (analyticsPushDecision?.enabled) {
      if (!decision?.enabled) return;
      // Analytics are pushed only for bucketed users
      if (decision.variationKey === NOT_BUCKETED) {
        disableForNotBucketed.current = false;
      } else if (!wasAnalyticsPushed?.current) {
        // Analytics are pushed only once when a flag is enabled
        analytics.push(() => onOptimizelyDecisionMade(decision as DecisionAnalyticsType));
        wasAnalyticsPushed.current = true;
      }
    }
  }, [decision, analytics, analyticsPushDecision]);
  const enabled = disableForNotBucketed.current;
  return [{ ...decision, enabled }];
};

export default useTrackedDecision;
