import { Grid, MenuItem, Theme, Typography } from '@material-ui/core';
import React, { MutableRefObject } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Dropdown from '../../dropdown';
import ResponsiveImageRender from '../../../common/ResponsiveImageRender';
import AnchorLink from '../../../common/AnchorLink';
import fontStyles from '../../../common/fontStyles';
import colors from '../../../common/colors';
import { getJoinedText } from '../../../common/string-formatter';
import { mobileStartBreakpoint } from '../../../materialUi/theme';
import categoryListItem from '../../dropdown/categoryListItem';
import { handleLogout } from '../../../common/logout/actions';
import { onSignInSignOutClick } from '@/dataAnalytics/dataAnalyticsHelper';
import { actions as profileActions } from '@/account/profile/profile.slice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import {localizationSelectors} from "@/localization/localizationSelectors";
import { setIsAuthenticated } from '@/../optimizely/utils/attributeHelpers';
import authService from '@/services/authService';
import telemetry from '@/telemetry';
import { useDecision } from '@optimizely/react-sdk';

interface ProfileDropdownProps {
  isHutRewardsMember?: boolean;
  upgradeToRewards: UpgradeToRewards;
  profileLinks: ProfileLinks;
  open: boolean;
  handleClose: () => void;
  gridRef: MutableRefObject<any>;
  profileRef: MutableRefObject<any>;
  menuItemClassName: string;
}

interface LegacyMemberProps {
  upgradeToRewards: UpgradeToRewards,
  classes: { [key: string]: string; };
}

interface ProfileSignOutProps {
  menuItemClassName: string;
  signOutClassName: string;
  signOut: LinkContent;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  legacyMemberUpgrade: {
    borderBottom: '1px solid rgb(207, 206, 204)',
    padding: '24px 16px 25px 16px'
  },
  legacyMemberUpgradeBorder: {
    border: `1px solid ${colors.red}`,
    borderRadius: 5,
    padding: '16px 10px 16px 10px'
  },
  upgradeNowImage: {
    position: 'absolute',
    top: 15,
    left: 30,
    backgroundColor: colors.white,
    objectFit: 'contain',
    width: 130,
    height: 20
  },
  joinText: {
    fontSize: 14,
    fontFamily: 'inherit',
    lineHeight: '23px',
    marginBottom: 16
  },
  upgradeNowText: {
    display: 'flex'
  },
  upgradeNowLink: {
    ...fontStyles.redTextLink,
    display: 'block',
    textDecoration: 'none'
  },
  signOutMenuItem: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '10px 16px',
    alignItems: 'center',
    ...fontStyles.headerSubNavLinks,
    '&:hover': {
      textDecoration: 'none'
    },
    color: colors.blue
  },
  caratIcon: {
    ...fontStyles.caretIcon,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.caretIconMobile
    }
  }
}));

const LegacyMemberUpgradeToRewards = ({ upgradeToRewards, classes }: LegacyMemberProps) => {
  const linkDisplayText = upgradeToRewards?.ctaLink?.linkDisplayText?.toUpperCase();
  return (
    <div className={classes.legacyMemberUpgrade}>
      <div className={classes.legacyMemberUpgradeBorder}>
        <ResponsiveImageRender
          className={classes.upgradeNowImage}
          testId="header-HR_pizza-image"
          {...upgradeToRewards.hutRewardsLogo}
        />
        <Typography data-testid="header-HR_legacy-text" className={classes.joinText}>
          {upgradeToRewards.joinText}
        </Typography>
        <AnchorLink
          className={`${classes.upgradeNowLink} tabOrderLink`}
          testId="header-HR_legacy-link"
          dataAnalyticsCategory="global_header"
          dataAnalyticsAction={linkDisplayText}
          {...upgradeToRewards.ctaLink}
          linkDisplayText={linkDisplayText}
          tabIndex={0}
        >
          <div className={classes.upgradeNowText}>
            <Typography className={classes.upgradeNowLink}>
              {linkDisplayText}
            </Typography>
            <ArrowForwardIosIcon fontSize="small" className={classes.caratIcon} />
          </div>
        </AnchorLink>
      </div>
    </div>
  );
};

const ProfileSignOut = ({ menuItemClassName, signOutClassName, signOut }: ProfileSignOutProps) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const localizationToken = useSelector(localizationSelectors.localizationToken);

  const [{ enabled: yumLogoutFixEnabled }] = useDecision('fr-web-3642-yum_logout_fix');

  const logoutYum = async () => {
    try {
      await authService.logoutYum();
      logout();
    } catch (err) {
      const error = err instanceof Error ? err : new Error(typeof err === 'string' ? err : 'Unknown error');
      telemetry.addNoticeError(error);
    }
  }

  const logout = () => {
    dispatch(handleLogout(localizationToken));
    dispatch(profileActions.logout());
    setIsAuthenticated(false);
    analytics.push(() => onSignInSignOutClick('Sign Out'));
  };

  return (
    <MenuItem
      disableRipple
      className={menuItemClassName}
      data-analytics-category="global_header"
      data-analytics-action={signOut?.linkDisplayText}
      data-testid={`header-${getJoinedText(signOut?.linkDisplayText)}-link`}
      onClick={yumLogoutFixEnabled ? logoutYum : logout}
      tabIndex={0}
    >
      <Grid item className={signOutClassName}>
        {signOut?.linkDisplayText}
      </Grid>
    </MenuItem>
  );
};

const ProfileDropdown = ({
  menuItemClassName,
  profileLinks: {
    orders, rewards, profile, signOut
  },
  upgradeToRewards,
  handleClose,
  isHutRewardsMember,
  profileRef,
  gridRef,
  open
}: ProfileDropdownProps) => {
  const classes = useStyles();
  const createDropdownItems = (items: LinkContent[]) => items.map(
    (category: LinkContent, index: number) => {
      const testId = `header-${getJoinedText(category.linkTitle)}-link`;
      return categoryListItem(category, index, testId, 'header-profile-dropdown-item-click');
    }
  );

  const linksForAllMembers: LinkContent[] = [orders, profile];
  const dropdownItems = createDropdownItems(isHutRewardsMember
    ? [...linksForAllMembers, rewards]
    : linksForAllMembers);

  // For legacy rewards members (customer details call failed = undefined)
  if (isHutRewardsMember === false) {
    dropdownItems.unshift(...[
      <LegacyMemberUpgradeToRewards key="legacyMember" upgradeToRewards={upgradeToRewards} classes={classes} />
    ]);
  }

  dropdownItems.push(
    <ProfileSignOut
      key="signOut"
      signOut={signOut}
      menuItemClassName={menuItemClassName}
      signOutClassName={classes.signOutMenuItem}
    />
  );

  return (
    <Dropdown
      gridRef={gridRef}
      buttonRef={profileRef}
      open={open}
      placement="bottom-end"
      handleClose={handleClose}
      testId="header"
      items={dropdownItems}
      width="330px"
      anchor="right"
    />
  );
};

export default ProfileDropdown;
