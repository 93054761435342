import { useState, useEffect } from 'react';

const useGetVersion = () => {
  const [version, setVersion] = useState('Searching...');

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          `https://${window?.location.hostname}/api/diagnostics`,
          { method: 'GET' }
        );
        const { BUILD_ID } = await res.json();
        if (BUILD_ID === 'undefined') {
          throw Error();
        }
        setVersion(BUILD_ID);
      } catch (error) {
        setVersion('Unavailable');
      }
    })();
  }, []);

  return version;
};

export default useGetVersion;
