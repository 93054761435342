import { Grid } from '@material-ui/core';
import React from 'react';
import useRailLayoutStyles from './styles';

type Props = {
  children: JSX.Element;
} & Record<string, unknown>;

export const RailCtasContainer = ({ children, ...props }: Props): JSX.Element => {
  const classes = useRailLayoutStyles();

  return (
    <Grid container direction="column" className={classes.ctasContainer} {...props}>
      {children}
    </Grid>
  );
};
