import React from 'react';

export const DeliveryIcon = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="delivery-icon"
    role="presentation"
  >
    <circle cx="5.4375" cy="18.375" r="1.875" fill="#CFCECC" />
    <circle cx="18.75" cy="18.375" r="1.875" fill="#CFCECC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.28914 12.4646L5.40592 9.78742C5.7886 9.28976 6.39296 8.99751 7.03421 9.00002H15.078C15.629 9.00427 16.1559 9.21943 16.5434 9.59844L19.5395 12.5276L23.2698 13.6299C23.8601 13.7909 23.9672 14.2974 24 14.8898V17.6614C23.9682 18.4191 23.6615 19.031 22.879 19.0787H21.4061C21.0479 20.0236 19.9732 21 18.7032 21C17.4331 21 16.3584 20.0236 16.0002 19.0787H8.07248C7.71426 20.0236 6.77368 21 5.50362 21C4.23355 21 3.15888 20.0236 2.80066 19.0787H1.4329C0.650427 19.031 0.031805 18.4191 0 17.6614V14.5118C0.0224304 13.9531 0.397602 13.4651 0.944408 13.2835L3.28914 12.4646ZM18.75 20.25C19.7855 20.25 20.625 19.4105 20.625 18.375C20.625 17.3395 19.7855 16.5 18.75 16.5C17.7145 16.5 16.875 17.3395 16.875 18.375C16.875 19.4105 17.7145 20.25 18.75 20.25ZM7.3125 18.375C7.3125 19.4105 6.47303 20.25 5.4375 20.25C4.40197 20.25 3.5625 19.4105 3.5625 18.375C3.5625 17.3395 4.40197 16.5 5.4375 16.5C6.47303 16.5 7.3125 17.3395 7.3125 18.375Z" fill="black" />
    <path d="M0.75705 17.7553C0.75705 17.7867 0.98455 18.1467 1.01669 18.1467H2.83704C3.04585 16.8114 4.07345 15.7413 5.45462 15.7413C6.83579 15.7413 7.83537 16.8114 8.04418 18.1467H15.9944C16.243 16.8539 17.3113 15.6942 18.6563 15.6942C20.0014 15.6942 21.1508 16.8539 21.3995 18.1467H22.7595C23.115 18.1689 23.25 17.7066 23.25 17.6751L23.2424 14.5493L22.7576 14.2429L18.9107 13.208C18.7875 13.1761 18.6761 13.1107 18.5893 13.0194L15.5036 9.97006C15.3665 9.83614 15.1829 9.75758 14.9893 9.75H6.84133C6.6154 9.75686 6.40392 9.86027 6.26276 10.0329L3.94068 12.9184L3.6514 13.1385L0.938416 14.0174L0.76767 14.3217L0.75705 17.7553Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.60507 4.125L6.79548 6.12029L2.62504 7.91829L2.625 8.12923H19.125L19.125 7.91829L14.9545 6.12029L14.145 4.125H7.60507Z" fill="#E21216" />
  </svg>
);
