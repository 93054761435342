import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Grid } from '@material-ui/core';
import { cartNavBarSkeletonStyles } from './CartNavBarButton.styles';

const CartSkeleton = (): JSX.Element => {
  const classes = cartNavBarSkeletonStyles();

  return (
    <Grid className={classes.cartGrid} data-testid="cart-skeleton">
      <div className={classes.cartLink}>
        <Grid className={classes.cartImage}>
          <Skeleton animation="wave" variant="circle" />
        </Grid>
        <Skeleton animation="wave" />
      </div>
    </Grid>
  );
};

export default CartSkeleton;
