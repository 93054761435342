import { Button, ClickAwayListener, Grid } from '@material-ui/core';
import React, { MutableRefObject, useRef } from 'react';
import ProfileDropdown from './ProfileDropdown';
import ProfileSection from '../profileSection';

interface SignedInProfileProps {
  isHutRewardsMember?: boolean;
  upgradeToRewards: UpgradeToRewards,
  profileLinks: ProfileLinks;
  open: boolean;
  toggleOpen: () => void;
  handleClose: () => void;
  gridRef: MutableRefObject<any>;
  classes: { [key: string]: string; };
  profileSectionProps: any;
}

const SignedInProfile = ({
  classes,
  isHutRewardsMember,
  upgradeToRewards,
  profileLinks,
  gridRef,
  open,
  toggleOpen,
  handleClose,
  profileSectionProps
}: SignedInProfileProps) => {
  const profileRef = useRef(null);

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Grid
          item
          className={classes.profileGrid}
          onClick={toggleOpen}
        >
          <Button
            className={classes.profileDropdown}
            aria-controls={open ? 'header-list-grow' : undefined}
            aria-haspopup="true"
            data-testid="profile-section"
            data-analytics-category="global_header"
            data-analytics-action={isHutRewardsMember ? 'Points' : 'Hi'}
            disableRipple
            ref={profileRef}
          >
            <ProfileSection
              {...profileSectionProps}
              classes={classes}
              open={open}
            />
          </Button>
          <ProfileDropdown
            menuItemClassName={classes.menuList}
            isHutRewardsMember={isHutRewardsMember}
            profileLinks={profileLinks}
            upgradeToRewards={upgradeToRewards}
            handleClose={handleClose}
            gridRef={gridRef}
            profileRef={profileRef}
            open={open}
          />
        </Grid>
      </ClickAwayListener>
    </>
  );
};

export default SignedInProfile;
