import getConfig from 'next/config';
import React, {
  useCallback, useEffect, useRef
} from 'react';
import { useDispatch } from 'react-redux';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { closeRail } from '@/rail/slices/Rail.slice';
import { RailUrlStatus, RESET_PASSWORD_TITLE, SIGN_IN_TITLE } from '@/rail/railContent/signInRail/constants';
import useYumChallengeUrl, { YumAction } from '@/hooks/useYumChallengeUrl';
import {
  YumMessagePredicateWithCallback
} from '@/rail/railContent/signInRail/signInIframe/hooks/UseYumAuthListener.types';
import useYumAuthListener, {
  messageTypeMatches
} from '@/rail/railContent/signInRail/signInIframe/hooks/useYumAuthListener';
import telemetry from '@/telemetry';
import {
  isAuthFailure,
  isForgotPassword,
  isForgotPasswordConfirmation,
  isInitialized
} from '@/rail/railContent/signInRail/signInIframe/hooks/useYumSignIn';
import { INITIALIZATION_TIME_LIMIT } from '@/configuration/constants';
import { openModal } from '@/localization/actions';
import useQueryParams from '@/rail/railContent/signInRail/useQueryParams';

const { publicRuntimeConfig } = getConfig();

export const isResetSuccessful = messageTypeMatches('idp-password-reset-successful');
export const isResetErrorNewLink = messageTypeMatches('idp-reset-error-new-link');
export const isResetErrorSignIn = messageTypeMatches('idp-reset-error-sign-in');
export const isResetInitialized = messageTypeMatches('idp-reset-password-initialized');

const YumResetPasswordIframe = ({
  title, setRailTitle, resetCode, setResetCode
}: {
  title?: string; setRailTitle: (title: string) => void; resetCode: string; setResetCode: (code?: string) => void;
}) => {
  const dispatch = useDispatch();
  const url = useYumChallengeUrl({
    action: YumAction.RESET,
    url: `${publicRuntimeConfig.YUM_BASE_URL}/idp/reset_password`,
    resetCode
  });
  const failureTimeout = useRef<NodeJS.Timeout>();
  const { addQueryParam, removeQueryParam } = useQueryParams();

  const onInitFailure = useCallback(() => {
    telemetry.addCustomEvent('Yum Reset Password iframe failed to initialize');
    dispatch(openModal({
      title: 'We\'re sorry',
      body: 'Something went wrong. Please try again.',
      cta: {
        text: 'OK'
      }
    }));
    removeQueryParam('reset_code');
    dispatch(closeRail(RailType.SIGN_IN));
  }, [dispatch, removeQueryParam]);

  const isUiInitialized = useCallback(() => {
    telemetry.addCustomEvent('onYumResetPasswordIframeInitialized');
    clearTimeout(failureTimeout.current);
    setRailTitle(SIGN_IN_TITLE);
  }, [setRailTitle]);

  const onIframeInitialized = useCallback(() => {
    telemetry.addCustomEvent('onYumResetPasswordIframeInitialized');
    clearTimeout(failureTimeout.current);
    setRailTitle(RESET_PASSWORD_TITLE);
  }, [setRailTitle]);

  const onResetSignIn = useCallback(() => {
    setRailTitle(SIGN_IN_TITLE);
    setResetCode(undefined);
  }, [setRailTitle, setResetCode]);

  const onResetSuccess = useCallback(() => {
    setRailTitle(SIGN_IN_TITLE);
    setResetCode(undefined);
    addQueryParam('railStatus', RailUrlStatus.RESET);
  }, [setRailTitle, setResetCode, addQueryParam]);

  const onIsForgotPasswordOrConfirmation = useCallback(() => {
    setRailTitle(RESET_PASSWORD_TITLE);
  }, [setRailTitle]);

  const listeners = useRef<YumMessagePredicateWithCallback[]>([
    { predicate: isInitialized, callback: isUiInitialized },
    { predicate: isAuthFailure, callback: onInitFailure },
    { predicate: isResetInitialized, callback: onIframeInitialized },
    { predicate: isResetSuccessful, callback: onResetSuccess },
    { predicate: isResetErrorSignIn, callback: onResetSignIn },
    { predicate: isForgotPassword, callback: onIsForgotPasswordOrConfirmation },
    { predicate: isForgotPasswordConfirmation, callback: onIsForgotPasswordOrConfirmation }
  ]);
  useYumAuthListener(listeners.current);

  useEffect(() => {
    failureTimeout.current = setTimeout(() => {
      onInitFailure();
    }, INITIALIZATION_TIME_LIMIT);

    return () => {
      clearTimeout(failureTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <iframe
      title={title}
      data-testid="yum-reset-password-iframe"
      height="500px"
      width="100%"
      src={`${url}`}
    />
  );
};

export default YumResetPasswordIframe;
