import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useLoyaltyPoints } from '@/clientCore/loyalty';

import ResponsiveImageRender from '../../common/ResponsiveImageRender';
import { Device, HiddenOnDevice } from '../../common/ResponsiveContext';

import CustomerGreeting from './customerGreeting';
import ProfileArrowIcon from './profileArrowIcon';
import HutLoyaltyPoints from './hutLoyaltyPoints';

interface ProfileSectionProps {
  profileIcons: HeaderProfileIcons;
  classes: { [key: string]: string; };
  open: boolean;
  windowSize: any;
  customerDetails: CustomerDetails;
  signIn: LinkContent;
}

const ProfileSection = ({
  signIn, customerDetails, profileIcons, classes, windowSize, open
}: ProfileSectionProps) => {
  const { currentPoints } = useLoyaltyPoints();

  const profileIcon = customerDetails ? { ...profileIcons?.signedIn } : { ...profileIcons?.guest };
  const availablePoints = currentPoints !== undefined || (customerDetails && customerDetails?.availablePoints !== undefined);

  return (
    <>
      <ResponsiveImageRender
        testId={`header-${customerDetails ? 'signed_in' : 'guest'}_profile_icon-image`}
        {...profileIcon}
        className={classes.profileIcon}
        ariaHidden
      />
      <Grid item className={classes.accountSection}>
        {availablePoints && (
          <HutLoyaltyPoints
            customerDetails={customerDetails}
            classes={classes}
          />
        )}
        <HiddenOnDevice {...Device.MOBILE}>
          {!customerDetails
          && (
            <Typography
              data-testid="header-hut_rewards-text"
              className={classes.hutRewards}
            >    {profileIcons?.guestHeading}
            </Typography>
          )}
        </HiddenOnDevice>
        <div className={classes.accountLink}>
          <div className={classes.signInLink}>
            {customerDetails
              ? <CustomerGreeting customerDetails={customerDetails} windowSize={windowSize} />
              : <HiddenOnDevice xsDown><>{signIn.linkDisplayText}</></HiddenOnDevice>}
          </div>
          <ProfileArrowIcon customerDetails={customerDetails} classes={classes} open={open} />
        </div>
      </Grid>
    </>
  );
};

export default ProfileSection;
