interface Section {
  meta: {
    title: string;
  };
  links: {
    links: unknown[];
  };
}

const getNavigationFooterList = (
  gqlResponse: any,
  signedIn = false,
  handleLogout: () => void,
  handleToggleRail: () => void,
) => {
  const {
    menu: { sectionName: menuSectionName, subLinks: menuSubLinks },
    account: {
      sectionName: accountSectionName,
      createAccount,
      signIn,
      manageAccount,
      signOut
    },
    sections: { sections }
  } = gqlResponse?.footer?.navigation;

  // Get other footer links for Menu category
  const categories = gqlResponse?.categories;

  const getSignOutLinkDetails = (signOutDetails: Object) => ({
    ...signOutDetails,
    link: undefined,
    onClick: handleLogout,
    tabIndex: 0
  });

  const signInLinks = {
    ...signIn,
    preventHref: true,
    onClick: handleToggleRail
  };

  const accountSectionLinks = signedIn
    ? [manageAccount, getSignOutLinkDetails(signOut)]
    : [createAccount, signInLinks];

  return [
    {
      sectionName: menuSectionName,
      links: [
        ...categories.map(
          (category: Record<string, unknown>) => category?.header || category
        ),
        ...menuSubLinks
      ]
    },
    {
      sectionName: accountSectionName,
      links: accountSectionLinks
    },
    ...sections.map(({ meta: { title }, links: { links } }: Section) => ({
      sectionName: title,
      links
    }))
  ];
};

const getMobileAppAndSocialMediaList = (
  gqlResponse: any
): MobileAppSocialMedia => {
  const {
    heading,
    appleStoreIcon,
    appleStoreLink,
    externalLinkIcon,
    googlePlayStoreIcon,
    googlePlayStoreLink,
    fbIcon,
    fbLink,
    instagramIcon,
    instagramLink,
    twitterIcon,
    twitterLink,
    youtubeIcon,
    youtubeLink
  } = gqlResponse?.footer?.cta;

  return {
    heading,
    externalLink: externalLinkIcon,
    apple: {
      image: { ...appleStoreIcon },
      link: { ...appleStoreLink }
    },
    google: {
      image: { ...googlePlayStoreIcon },
      link: { ...googlePlayStoreLink }
    },
    facebook: {
      image: { ...fbIcon },
      link: { ...fbLink }
    },
    instagram: {
      image: { ...instagramIcon },
      link: { ...instagramLink }
    },
    twitter: {
      image: { ...twitterIcon },
      link: { ...twitterLink }
    },
    youtube: {
      image: { ...youtubeIcon },
      link: { ...youtubeLink }
    }
  };
};

const getLegalDisclaimerList = (gqlResponse: any): LegalDisclaimer => {
  const {
    links: { links },
    disclaimers: { legalCopyright, legalStatement }
  } = gqlResponse?.footer.legal;

  return {
    links,
    disclaimers: { legalCopyright, legalStatement }
  };
};

export {
  getNavigationFooterList,
  getMobileAppAndSocialMediaList,
  getLegalDisclaimerList
};
