import React from 'react';

import { Box } from '@material-ui/core';

import { TDeliveryInfo } from './types';

const DeliveryInfo = ({
  address, city, state, zipcode
}: TDeliveryInfo) => (
  <Box mb={3}>
    <Box fontWeight="fontWeightBold">Delivery to:</Box>

    <Box>{address}</Box>
    <Box>{`${city}, ${state}, ${zipcode}`}</Box>
  </Box>
);

export default DeliveryInfo;
