import { Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Link from 'next/link';
import React from 'react';
import getConfig from 'next/config';
import { overridesPresent } from '@/configuration/helpers/cookies';
import useClientSideState from '../../common/useClientSideState';

const { publicRuntimeConfig } = getConfig();

const OverridesAlert = (): JSX.Element => {
  const [visible] = useClientSideState(false, (setVisible) => {
    if (overridesPresent() && publicRuntimeConfig.ENVIRONMENT_NAME !== 'prod') {
      setVisible(true);
    }
  });

  return (
    <>
      { visible
        && (
        <Grid container suppressHydrationWarning>
          <Grid item xs={12}>
            <Alert severity="warning">
              <strong>{'You\'ve got overrides: '}</strong>
              <Link href="/config">
                <Button href="/config" color="primary" size="small">change configuration</Button>
              </Link>
            </Alert>
          </Grid>
        </Grid>
        )}
    </>
  );
};

export default OverridesAlert;
